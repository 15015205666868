import React, { useState, useEffect } from "react";
import Artist from "./Artist";
import artistsData from "../data/artists.json";
import ArtistModal from "./ArtistModal";
import { useCustomTranslation } from "../hooks/useCustomTranslation";
import { useModal } from "../context/ModalContext";

function shuffleArray(array: any) {
  const arr = array.slice(); // Create a copy of the array
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]]; // Swap elements
  }
  return arr;
}
type ArtistType = {
  id: number;
  imageUrl: string;
  translationKey: string;
};
const ArtistList: React.FC = () => {
  const { openModal } = useModal();
  const { t } = useCustomTranslation();
  const initialCount = 4;
  const [displayCount, setDisplayCount] = useState(initialCount);
  const [shuffledArtists, setShuffledArtists] = useState<ArtistType[]>([]);

  const loadMoreArtists = () => {
    setDisplayCount((prevCount) => prevCount + 5);
  };
  const showLessArtists = () => {
    setDisplayCount(initialCount);
  };

  useEffect(() => {
    setShuffledArtists(shuffleArray(artistsData));
  }, [artistsData]);

  return (
    <div className="flex flex-col items-center justify-center">
      {shuffledArtists.slice(0, displayCount).map((artist) => (
        <div
          key={artist.id}
          className="flex items-center w-11/12 p-4 mb-4 transition-transform rounded-lg md:mb-8 md:w-3/4 h-18 bg-slate-800 hover:bg-slate-700 hover:scale-105"
          onClick={() => openModal(artist)}
          style={{ cursor: "pointer" }}
        >
          <img
            src={artist.imageUrl}
            alt={t(`${artist.translationKey}.name`)}
            className="object-cover w-16 h-16 mr-4 rounded-full"
          />

          <div>
            <h2 className="text-lg font-semibold text-slate-300">
              {t(`${artist.translationKey}.name`)}
            </h2>
            <p className="text-sm italic text-slate-400">
              {t(`${artist.translationKey}.genre`)}
            </p>
            <p className="text-sm text-slate-400">
              <span className="md:hidden">
                {t(`${artist.translationKey}.bio`).length > 70
                  ? t(`${artist.translationKey}.bio`).slice(0, 70) + " ..."
                  : t(`${artist.translationKey}.bio`)}
              </span>

              {/* Longer text for medium screens and up */}
              <span className="hidden lg:hidden md:inline">
                {t(`${artist.translationKey}.bio`).length > 150
                  ? t(`${artist.translationKey}.bio`).slice(0, 150) + " ..."
                  : t(`${artist.translationKey}.bio`)}
              </span>
              <span className="hidden lg:inline">
                {t(`${artist.translationKey}.bio`).length > 250
                  ? t(`${artist.translationKey}.bio`).slice(0, 250) + " ..."
                  : t(`${artist.translationKey}.bio`)}
              </span>
            </p>
          </div>
        </div>
      ))}
      {displayCount < artistsData.length && (
        <div
          onClick={loadMoreArtists}
          className="px-4 py-2 mt-4 text-white cursor-pointer hover:text-sky-400"
        >
          {t("show_more")}
        </div>
      )}
      {displayCount > initialCount && (
        <div
          onClick={showLessArtists}
          className="px-4 py-2 text-white cursor-pointer hover:text-sky-400"
        >
          {t("show_less")}
        </div>
      )}
    </div>
  );
};

export default ArtistList;
