import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContactForm from "../components/ContactForm";
import { useCustomTranslation } from "../hooks/useCustomTranslation";

type ContactProps = {};

const Contact: React.FC<ContactProps> = () => {
  const { t } = useCustomTranslation();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen mt-10">
      <div className="z-0 flex flex-col w-11/12 m-4 overflow-hidden text-center rounded-lg md:m-10 bg-slate-800 md:w-3/4">
        <ToastContainer />
        <h3 className="uppercase tracking-[5px] text-gray-300 text-2xl md:text-3xl mt-14 md:mt-18 font-bold">
          {/* Contact Us */}
          {t("contact.contact")}
        </h3>
        <div className="flex flex-col items-center justify-center w-full mt-4 space-y-3 overflow-auto sm:space-y-5 sm:mt-10 md:mt-16">
          <div className="flex items-center justify-center w-1/2 mt-5 mb-10 md:mb-16">
            <div className="flex flex-col items-center mr-4 space-y-4">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="text-lg md:text-2xl text-sky-400"
              />
              <FontAwesomeIcon
                icon={faMapMarker}
                className="text-lg md:text-2xl text-sky-400"
              />
            </div>
            <div className="flex flex-col items-start space-y-2">
              <a
                href="mailto:mpeventsberlin@gmail.com"
                className="ml-3 transition-all duration-300 ease-out sm:text-xl md:text-2xl hover:rounded-full text-slate-400 hover:text-sky-400"
              >
                info@mpeventsberlin.com
              </a>
              <p className="ml-3 sm:text-xl md:text-2xl text-slate-400">
                Berlin, DE
              </p>
            </div>
          </div>
        </div>
        <ContactForm />
      </div>
    </div>
  );
};
export default Contact;
