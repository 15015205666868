// ModalContext.js
import React, { useState, createContext, useContext } from "react";
import ArtistModal from "../components/ArtistModal";
interface Artist {
  id: number;

  imageUrl: string;
  translationKey: string;
}

interface ModalContextType {
  isModalOpen: boolean;
  selectedArtist: Artist | null;
  openModal: (artist: Artist) => void;
  closeModal: () => void;
}

interface ModalProviderProps {
  children: React.ReactNode;
}
const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState<Artist | null>(null);

  const openModal = (artist: Artist) => {
    setSelectedArtist(artist);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedArtist(null);
  };

  return (
    <ModalContext.Provider
      value={{ isModalOpen, selectedArtist, openModal, closeModal }}
    >
      {children}
      {isModalOpen && (
        <ArtistModal artist={selectedArtist} onClose={closeModal} />
      )}
    </ModalContext.Provider>
  );
};
