import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMusic,
  faClock,
  faPuzzlePiece,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { useCustomTranslation } from "../hooks/useCustomTranslation";

const Description: React.FC = () => {
  const { t } = useCustomTranslation();

  return (
    <div className="min-h-screen p-6 bg-slate-900 md:p-8 lg:p-16">
      <div className="flex-grow">
        <h1 className="mb-16 text-4xl font-bold text-center text-gray-300 md:text-5xl md:mb-14 ">
          MP Events
          <h2 className="mt-4 text-2xl md:text-3xl">
            {t("description.intro")}
          </h2>
        </h1>

        <section className="mb-14 md:flex md:mb-16">
          <h2 className="mb-6 text-2xl font-semibold text-center text-sky-500 md:w-5/12 md:mr-8">
            <FontAwesomeIcon
              icon={faMusic}
              className="mr-8 text-2xl md:text-3xl text-sky-700"
            />{" "}
            {t("description.one")}
          </h2>
          <p className="text-center text-md md:text-lg text-slate-400 md:w-7/12">
            {t("description.oneText")}
          </p>
        </section>

        <section className="mb-14 md:flex md:mb-16">
          <h2 className="mb-6 text-2xl font-semibold text-center text-sky-500 md:w-5/12 md:mr-8">
            <FontAwesomeIcon
              icon={faClock}
              className="mr-8 text-2xl md:text-3xl text-sky-700"
            />{" "}
            {t("description.two")}
          </h2>
          <p className="text-lg text-center text-slate-400 md:w-7/12">
            {t("description.twoText")}
          </p>
        </section>

        <section className="mb-10 md:flex md:mb-14">
          <h2 className="mb-6 text-2xl font-semibold text-center text-sky-500 md:w-5/12 md:mr-8">
            <FontAwesomeIcon
              icon={faPuzzlePiece}
              className="mr-8 text-2xl md:text-3xl text-sky-700"
            />{" "}
            {t("description.three")}
          </h2>
          <p className="text-lg text-center text-slate-400 md:w-7/12">
            {t("description.threeText")}
          </p>
        </section>

        <section className="mb-10 md:flex md:mb-14">
          <h2 className="mb-6 text-2xl font-semibold text-center text-sky-500 md:w-5/12 md:mr-8">
            <FontAwesomeIcon
              icon={faThumbsUp}
              className="mr-8 text-2xl md:text-3xl text-sky-700"
            />{" "}
            {t("description.four")}
          </h2>
          <p className="mb-4 text-lg text-center text-slate-400 md:w-7/12">
            {t("description.fourText")}
          </p>
        </section>
      </div>
      <div className="border-b border-slate-300/10"></div>
    </div>
  );
};

export default Description;
