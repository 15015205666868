import React from "react";
import ScrollToTopButton from "../components/ScrollToTop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import { useCustomTranslation } from "../hooks/useCustomTranslation";

type AboutProps = {};

const About: React.FC<AboutProps> = () => {
  const { i18n, t } = useCustomTranslation();

  return (
    <div className="flex flex-col min-h-screen pb-4 bg-slate-900">
      <div
        className="relative flex items-center justify-center flex-grow mt-8 mb-20 bg-center bg-cover md:mt-20"
        // style={{ backgroundImage: `url('/mp1.jpg')` }}
      >
        <div className="text-center">
          <img
            src="logo_mp.png"
            alt="Logo"
            className="w-40 h-40 mx-auto"
            // style={{ maxWidth: "100px" }}
          />

          <h1 className="m-10 text-lg md:text-xl lg:text-3xl text-sky-400">
            {/* Electrify Your Event with MP Events DJs */}
            {t("about.intro")}
            {/* – Inquire for Details! */}
          </h1>

          <p className="text-lg text-slate-300 m-7 md:m-20 lg:m-30 lg:text-xl">
            {t("about.one")}
            <br />
            {t("about.two")}
            <br />
            {t("about.three")}
            <br />
            <br />
          </p>

          <div className="flex justify-center mt-20 space-x-8">
            <a
              href="https://www.facebook.com/profile.php?id=61552710011577"
              target="_blank"
              rel="noopener noreferrer"
              className="text-4xl text-indigo-600 hover:text-gray-400"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              href="https://www.instagram.com/mpevents_berlin/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-4xl text-indigo-600 hover:text-gray-400"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
      <div className="my-4 border-b border-slate-300/10"></div>
    </div>
  );
};
export default About;
