import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useCustomTranslation } from "../hooks/useCustomTranslation";

const Footer: React.FC = () => {
  const { i18n, t } = useCustomTranslation();
  return (
    <footer className="bg-slate-900 text-slate-400">
      <div className="container px-4 py-6 mx-auto mt-20 border-t border-gray-700">
        <div className="flex flex-col items-center justify-between mt-6 text-center md:flex-row md:items-start md:text-left">
          {/* Footer Section 1 */}
          <div className="flex flex-col items-center w-full mb-16 md:w-1/3 md:mb-0">
            <h2 className="mb-6 text-xl font-semibold text-sky-400">
              {t("footer.one")}
              {/* Planning an Event? */}
              {/* <br />
              Still Missing the Music? */}
            </h2>
            <p className="w-4/5 leading-relaxed">
              {/* MP Events is here to elevate your celebration! With expert DJs and
              a flair for the perfect beat, we're your go-to for unforgettable
              party music. */}
              {t("footer.oneText")}
            </p>
          </div>
          {/* Footer Section 3 */}
          <div className="flex flex-col items-center w-full mb-16 md:w-1/3 md:mb-0">
            <h2 className="mb-6 text-xl font-semibold md:mb-10 text-sky-400">
              {/* Follow Us */}
              {t("footer.three")}
            </h2>
            <div className="flex justify-center space-x-8">
              <a
                href="https://www.facebook.com/profile.php?id=61552710011577"
                target="_blank"
                rel="noopener noreferrer"
                className="text-4xl text-pink-400 hover:text-gray-400"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://www.instagram.com/mpevents_berlin/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-4xl text-pink-400 hover:text-gray-400"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
          {/* Footer Section 2 */}
          <div className="flex flex-col items-center w-full mb-16 md:w-1/3 md:mb-0">
            <h2 className="mb-6 text-xl font-semibold text-sky-400">
              {t("footer.two")}
            </h2>
            <a
              href="https://www.momixing.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/mom.jpeg" alt="Mom logo" className="h-24 mx-auto" />
            </a>
            {/* <h2 className="mb-6 text-xl font-semibold text-sky-400">
              {t("footer.two")}
            </h2>
            <ul className="p-0 list-none ">
              <li className="mb-2 hover:text-slate-100">
                <a href="#about"> {t("section.home")}</a>
              </li>
              <li className="mb-2 hover:text-slate-100">
                <a href="#description">{t("section.who")}</a>
              </li>
              <li className="mb-2 hover:text-slate-100">
                <a href="#artist">{t("section.artists")}</a>
              </li>
              <li className="mb-2 hover:text-slate-100">
                <a href="#contact">{t("section.contact")}</a>
              </li>
            </ul> */}
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 text-center text-gray-500">
          &copy; {new Date().getFullYear()} Mp Events.
          {t("footer.right")}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
