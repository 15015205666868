import React, { useState } from "react";

const ScrollToTopButton: React.FC = () => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  // Add a scroll event listener to show/hide the button
  window.addEventListener("scroll", handleScroll);

  return (
    <button
      className={`fixed bottom-4 right-4 bg-blue-500 text-white p-2 md:p-4 rounded-full shadow-md z-10 ${
        showButton ? "visible" : "invisible"
      }`}
      onClick={scrollToTop}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-arrow-up"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M8 1a.5.5 0 0 1 .5.5V14a.5.5 0 0 1-1 0V1.5a.5.5 0 0 1 .5-.5z"
        />
        <path
          fillRule="evenodd"
          d="M7.646 2.146a.5.5 0 0 1 .708 0l4 4a.5.5 0 1 1-.708.708L8 3.207 3.354 7.854a.5.5 0 1 1-.708-.708l4-4a.5.5 0 0 1 0-.708z"
        />
      </svg>
    </button>
  );
};

export default ScrollToTopButton;
