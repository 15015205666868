import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link, Outlet, useNavigate } from "react-router-dom";
// import Navbar2 from "./Navbar2";

const Layout: React.FC = () => {
  return (
    <div className="flex flex-col">
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
