import React from "react";
import { useTranslation } from "react-i18next";

type testProps = {};

const Test1: React.FC<testProps> = () => {
  // @ts-ignore
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language;

  const toggleLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div>
      <div>hi from test</div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <button
          onClick={() => toggleLanguage("en")}
          disabled={currentLang === "en"}
          style={{
            marginRight: "5px",
            opacity: currentLang === "en" ? 0.5 : 1,
          }}
        >
          EN
        </button>
        |
        <button
          onClick={() => toggleLanguage("de")}
          disabled={currentLang === "de"}
          style={{ marginLeft: "5px", opacity: currentLang === "de" ? 0.5 : 1 }}
        >
          DE
        </button>
      </div>
      <h1>{t("welcome_message")}</h1>
      <h1>{t("section.home")}</h1>
      <img src="/mom.svg" alt="" className="w-40 h-40 bg-blue-500" />
    </div>
  );
};
export default Test1;
