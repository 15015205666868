import React, { useState, useEffect } from "react";
import { useCustomTranslation } from "../hooks/useCustomTranslation";
import { TFunction } from "i18next";

const getMenuItems = (t: TFunction) => [
  { name: t("section.home"), href: "/#about" },
  { name: t("section.who"), href: "/#description" },
  { name: t("section.artists"), href: "/#artist" },
  { name: t("section.contact"), href: "/#contact" },
];

const Navbar: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const { i18n, t } = useCustomTranslation();
  const menuItems = getMenuItems(t);

  const currentLang = i18n.language;

  const toggleLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  // const menuItems = [
  //   { name: "Home", href: "/#about" },
  //   { name: "Who We Are", href: "/#description" },
  //   { name: "Artists", href: "/#artist" },
  //   { name: "Contact", href: "/#contact" },
  // ];

  useEffect(() => {
    // Disable scroll when mobile menu is open
    if (mobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to reset the overflow when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [mobileMenuOpen]);

  return (
    <body className="bg-sky-500">
      <nav className="relative flex items-center justify-between px-4 py-4 bg-slate-900">
        <a
          className="flex items-center text-3xl font-bold leading-none"
          href="#"
        >
          <img
            src="./logo_mp.png"
            className="h-8 mr-3 md:h-12"
            alt="Flowbite Logo"
          />
          <span className="self-center text-2xl font-semibold text-gray-200 whitespace-nowrap">
            MP Events
          </span>
        </a>
        <div className="flex items-center justify-between md:hidden">
          <div className="flex text-gray-100">
            <button
              onClick={() => toggleLanguage("en")}
              disabled={currentLang === "en"}
              className={`pr-2 p-2 ${
                currentLang === "en" ? "opacity-100 font-bold" : "opacity-50"
              }`}
            >
              EN
            </button>
            <div className="py-2">|</div>

            <button
              onClick={() => toggleLanguage("de")}
              disabled={currentLang === "de"}
              className={`pl-2 p-2 ${
                currentLang === "de" ? "opacity-100 font-bold" : "opacity-50"
              }`}
            >
              DE
            </button>
          </div>
          <button
            className="flex items-center p-4 text-blue-600 navbar-burger"
            onClick={toggleMobileMenu}
          >
            {/* three dots svg */}
            <svg width="24" height="24" fill="none" aria-hidden="true">
              <title>Mobile menu</title>
              <path
                d="M12 6v.01M12 12v.01M12 18v.01M12 7a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm0 6a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </button>
        </div>

        <ul
          className={`md:flex md:items-center md:w-auto md:space-x-6 mr-4 ${
            mobileMenuOpen ? "hidden md:block" : "hidden"
          }`}
        >
          {menuItems.map((item, index) => (
            <li key={index}>
              <a
                className="text-gray-400 text-md hover:text-gray-100"
                href={item.href}
              >
                {item.name}
              </a>
            </li>
          ))}
          <div className="flex items-center justify-center ml-8 text-gray-100">
            <button
              onClick={() => toggleLanguage("en")}
              disabled={currentLang === "en"}
              className={`pr-2 p-2 ${
                currentLang === "en" ? "opacity-100" : "opacity-50"
              }`}
            >
              EN
            </button>
            |
            <button
              onClick={() => toggleLanguage("de")}
              disabled={currentLang === "de"}
              className={`pl-2 p-2 ${
                currentLang === "de" ? "opacity-100" : "opacity-50"
              }`}
            >
              DE
            </button>
          </div>
        </ul>
      </nav>

      <div
        className={`navbar-menu relative z-50 ${
          mobileMenuOpen ? "block" : "hidden"
        }`}
      >
        {/* <div
          className="fixed inset-0 bg-gray-800 opacity-25 navbar-backdrop"
          onClick={toggleMobileMenu}
        ></div> */}

        <div
          className="fixed inset-0 z-50 md:hidden"
          id="headlessui-dialog-:ra:"
          role="dialog"
          aria-modal="true"
          data-headlessui-state="open"
        >
          <div
            className="fixed inset-0 bg-black/20 backdrop-blur-sm dark:bg-slate-900/80"
            id="headlessui-dialog-overlay-:rf:"
            aria-hidden="true"
            data-headlessui-state="open"
            onClick={toggleMobileMenu}
          ></div>
          <div className="fixed w-full max-w-xs p-6 text-base font-semibold rounded-lg shadow-lg top-4 right-4 bg-slate-800 text-slate-400 dark:highlight-white/5">
            {/* <div className="flex pl-4 mb-4 text-gray-100 ">
              <button
                onClick={() => toggleLanguage("en")}
                disabled={currentLang === "en"}
                className={`mr-1 ${
                  currentLang === "en" ? "opacity-100" : "opacity-50"
                }`}
              >
                EN
              </button>
              |
              <button
                onClick={() => toggleLanguage("de")}
                disabled={currentLang === "de"}
                className={`ml-1 ${
                  currentLang === "de" ? "opacity-100" : "opacity-50"
                }`}
              >
                DE
              </button>
            </div> */}
            <button
              className="absolute navbar-close top-5 right-5"
              onClick={toggleMobileMenu}
            >
              {" "}
              {/* X close svg */}
              <svg
                className="w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
            {/* <div className="border-b border-gray-700"></div> */}

            <ul>
              {menuItems.map((item, index) => (
                <li className="mb-1" key={index}>
                  <a
                    className="block p-4 text-sm font-semibold rounded text-slate-400 hover:text-sky-400"
                    href={item.href}
                    onClick={() => {
                      toggleMobileMenu();
                    }}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </body>
  );
};

export default Navbar;
