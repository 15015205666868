import React from "react";
import ProductList from "../test/ProductList";
import artistsData from "../data/artists.json";
import Cards from "../test/Cards";
import ArtistSlider from "../components/ArtistSlider";
import ArtistList from "../components/ArtistList";

const Artist: React.FC = () => {
  return (
    <div className="flex flex-col min-h-screen p-2 text-white md:p-4 bg-slate-900">
      <ArtistSlider />
      <ArtistList />
      <div className="mt-6 border-b md:mt-12 border-slate-300/10"></div>
    </div>
  );
};

export default Artist;
