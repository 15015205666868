import React from "react";
import { Link } from "react-router-dom";
import ProductList from "../test/ProductList";
import About from "../sections/About";
import Artist from "../sections/Artist";
import Section4 from "../components/Section4";
import Contact from "../sections/Contact";
import ScrollToTopButton from "../components/ScrollToTop";
import Description from "../sections/Description";

const Home: React.FC = () => {
  return (
    <div className="flex flex-col bg-slate-900">
      <section id="about" className="snap-center">
        <About />
      </section>
      <section id="description" className="snap-center">
        <Description />
      </section>
      <section id="artist" className="snap-start">
        <Artist />
      </section>
      <section id="contact" className="snap-center">
        <Contact />
      </section>
    </div>
  );
};

export default Home;
