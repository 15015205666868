import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
// import "./App.css";
import Home from "./pages/Home";
import Missing from "./pages/Missing";
import Test from "./pages/Test";
import Product from "./test/Product";
import { useParams } from "react-router-dom";
import { ProductProps } from "./types/productsType";
import ProductPage from "./test/ProductPage";
import ArtistPage from "./pages/ArtistPage";

function App() {
  const { id } = useParams<{ id: string }>();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        {/* public routes */}
        <Route path="test" element={<Test />} />
        <Route path="/artist/:id" element={<ArtistPage />} />
        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
