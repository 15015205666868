import React, { useEffect, useRef } from "react";
import artistsData from "../data/artists.json";
import { useCustomTranslation } from "../hooks/useCustomTranslation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSoundcloud, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
interface Artist {
  id: number;

  imageUrl: string;
  translationKey: string;
}

interface ArtistModalProps {
  artist: Artist | null;
  onClose: () => void;
}

const ArtistModal: React.FC<ArtistModalProps> = ({ artist, onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const { t } = useCustomTranslation();

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    console.log("artist", artist);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    return () => {
      enableBodyScroll();
    };
  }, []);

  useEffect(() => {
    disableBodyScroll();

    return () => {
      enableBodyScroll();
    };
  }, []);

  const disableBodyScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableBodyScroll = () => {
    document.body.style.overflow = "";
  };

  if (!artist) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center p-4 bg-black bg-opacity-50 backdrop-blur-sm">
      <div
        className="w-[95%] h-[95%] md:h-auto max-w-4xl overflow-auto rounded-lg md:w-3/4 text-slate-200 bg-slate-800"
        ref={modalRef}
      >
        <div className="flex justify-end p-2">
          <button onClick={onClose} className="text-xl text-slate-300">
            &times;
          </button>
        </div>
        <div className="p-4 md:p-8 md:flex">
          <img
            src={artist.imageUrl}
            className="object-cover mx-auto md:mx-0 md:mr-8 h-52 w-52 rounded-xl"
          />
          <div className="flex flex-col justify-between md:ml-4">
            <div>
              <div className="flex justify-between mt-4 md:mt-0">
                <h1 className="text-2xl font-semibold ">
                  {t(`${artist.translationKey}.name`)}
                </h1>
              </div>
              <p className="mt-2 italic text-md">
                {t(`${artist.translationKey}.genre`)}
              </p>{" "}
              <div className="mt-4 ml-4 space-x-8">
                {t(`${artist.translationKey}.soundcloud`) && (
                  <a
                    href={t(`${artist.translationKey}.soundcloud`)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faSoundcloud}
                      size="2x"
                      className="hover:text-sky-400"
                    />{" "}
                  </a>
                )}
                {t(`${artist.translationKey}.instagram`) && (
                  <a
                    href={t(`${artist.translationKey}.instagram`)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      size="2x"
                      className="hover:text-sky-400"
                    />{" "}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="px-3 pb-12 overflow-y-auto md:px-8">
          <p className="overflow-y-auto max-h-80">
            {t(`${artist.translationKey}.bio`)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ArtistModal;
