import React, { useState, useEffect } from "react";
import Artist from "./Artist";
import artistsData from "../data/artists.json";
import { useModal } from "../context/ModalContext";
interface Artist {
  id: number;
  // name: string;
  // bio: string;
  imageUrl: string;
  translationKey: string;
}
const ArtistSlider: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { openModal } = useModal();
  const nextIndex = (currentIndex + 1) % artistsData.length;

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex(nextIndex);
    }, 3000);

    return () => clearTimeout(timer);
  }, [currentIndex, nextIndex]);

  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="flex flex-col items-center justify-center w-11/12 mx-4 my-8 h-1/2 ">
      <div className="flex space-x-4 lg:w-3/5">
        <div className="w-1/4">
          <Artist
            {...artistsData[
              (currentIndex - 1 + artistsData.length) % artistsData.length
            ]}
            position="left"
          />
        </div>
        <div className="w-1/2">
          <Artist {...artistsData[currentIndex]} position="center" />
        </div>
        <div className="w-1/4">
          <Artist {...artistsData[nextIndex]} position="right" />
        </div>
      </div>
      <div className="mt-4">
        <div className="flex px-4 space-x-2">
          {artistsData.map((artist, index) => (
            <button
              key={artist.id}
              onClick={() => handleDotClick(index)}
              className={`w-1 h-1 rounded-full px-3 my-4 ${
                index === currentIndex
                  ? "bg-white transform scale-125"
                  : "bg-gray-500"
              }`}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArtistSlider;
