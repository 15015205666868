import React from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCustomTranslation } from "../hooks/useCustomTranslation";

type Props = {};

type Inputs = {
  name: string;
  email: string;
  subject: string;
  message: string;
};
const service = process.env.REACT_APP_SERVICE_ID;
const template = process.env.REACT_APP_TEMPLATE_ID;
const publicKey = process.env.REACT_APP_PUBLIC_KEY;

const ContactForm = ({}: Props) => {
  const { i18n, t } = useCustomTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (formData: Inputs) => {
    const templateParams = {
      to_name: "Mp_events",
      from_name: formData.name,
      reply_to: formData.email,
      subject: formData.subject,
      message: `Subject: ${formData.subject}\n\n${formData.message}`,
    };

    try {
      const response = await emailjs.send(
        service,
        template,
        templateParams,
        publicKey
      );
      console.log("first");
      toast.success(t("contact.toast_success"), {
        position: toast.POSITION.BOTTOM_CENTER,
        style: {
          background: "rgba(211, 191, 148, 0.3)",
          color: "white",
        },
      });
      reset(); //  clear the form fields
    } catch (error) {
      toast.error(t("contact.toast_error"), {
        position: toast.POSITION.BOTTOM_CENTER,
        style: {
          background: "rgba(247, 171, 10, 0.4)",
          color: "white",
        },
      });
    }
  };

  return (
    <div className="w-full pb-8">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-4/5 mx-auto space-y-3 md:w-3/4 lg:w-2/3"
      >
        <div className="flex flex-col w-full space-y-3 sm:flex-row sm:space-y-0 sm:space-x-2 ">
          <input
            {...register("name", { required: true })}
            placeholder={t("contact.name")}
            className={`placeholder:text-gray-200 text-gray-200 w-full h-8 pl-[10px] contactInput rounded-md bg-slate-600  ${
              errors.name && "border-red-500 "
            }`}
            type="text"
          />

          <input
            {...register("email", {
              required: true,
              pattern: /^\S+@\S+$/i,
            })}
            placeholder={t("contact.email")}
            className={`placeholder:text-gray-200 text-gray-200 w-full h-8 pl-[10px] contactInput rounded-md bg-slate-600 ${
              errors.email && "border-red-500"
            }`}
            type="email"
          />
        </div>
        <input
          {...register("subject", { required: true })}
          placeholder={t("contact.subject")}
          className={`placeholder:text-gray-200 text-gray-200 pl-[10px] h-8 contactInput rounded-md bg-slate-600 ${
            errors.subject && "border-red-500"
          }`}
          type="text"
        />
        <textarea
          {...register("message", { required: true })}
          placeholder={t("contact.message")}
          className={`placeholder:text-gray-200  text-gray-200 pl-[10px] contactInput custom-scrollbar rounded-md h-[150px] md:h-[200px] bg-slate-600 mb-20 ${
            errors.message && "border-red-500"
          }`}
          // style={{ resize: "none", height: "200px" }}
        />
        <button
          type="submit"
          className="flex items-center justify-center w-1/3 h-10 px-5 py-2 mx-auto mt-12 mb-8 font-semibold text-gray-100 transition-all rounded-md bg-sky-700 hover:bg-sky-500 sm:py-5 sm:px-10 sm:text-lg md:mt-8"
        >
          {t("contact.send")}
        </button>
      </form>
    </div>
  );
};
export default ContactForm;
