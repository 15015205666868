import React, { useState, useEffect } from "react";
import { useCustomTranslation } from "../hooks/useCustomTranslation";
import { useModal } from "../context/ModalContext";

interface Artist {
  id: number;
  imageUrl: string;
  translationKey: string;
  position?: "left" | "center" | "right";
}

const ArtistComponent: React.FC<Artist> = ({
  id,
  imageUrl,
  position,
  translationKey,
}) => {
  const { t } = useCustomTranslation();
  const { openModal } = useModal();
  return (
    <div
      className={`relative ${
        position === "center" ? "" : "group"
      } cursor-pointer`}
      onClick={() => openModal({ id, imageUrl, translationKey })}
    >
      <div className="relative">
        <img
          src={imageUrl}
          alt={t(`${translationKey}.name`)}
          className="object-cover w-32 h-32 mx-auto rounded-lg md:w-48 md:h-48"
        />

        {position === "left" && (
          <div className="absolute inset-0 bg-gradient-to-r from-slate-900 to-transparent opacity-90 "></div>
        )}
        {position === "right" && (
          <div className="absolute inset-0 bg-gradient-to-l from-slate-900 to-transparent opacity-80 "></div>
        )}
      </div>
      <div className="absolute inset-0 flex flex-col items-center justify-end text-white">
        <h2 className="text-sm font-bold">{t(`${translationKey}.name`)}</h2>
      </div>
    </div>
  );
};

export default ArtistComponent;
