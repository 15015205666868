import React from "react";
import Test1 from "../components/Test1";
import { useTranslation } from "react-i18next";
type TestProps = {};

const lngs: any = {
  en: { nativeName: "English" },
  de: { nativeName: "Deutsch" },
};
const Test: React.FC<TestProps> = () => {
  // @ts-ignore
  const { t, i18n } = useTranslation();
  return (
    <>
      <div>hi from test</div>
      <p>{t("learn")}</p>
      <Test1 />
      <div>
        {Object.keys(lngs).map((lng: any) => (
          <button
            type="submit"
            key={lng}
            onClick={() => i18n.changeLanguage(lng)}
            disabled={i18n.resolvedLanguage === lng}
          >
            {lngs[lng].nativeName}
          </button>
        ))}
      </div>
    </>
  );
};
export default Test;
